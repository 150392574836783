<template>
  <div id="float-agent">
    <img src="@/assets/float-agent/13.png" v-on:click="showAgent()" style="z-index: 900;">
  </div>
</template>

<script setup>
import emitter from "./UniqEvent";
import {onMounted} from "vue";

function showAgent() {
  emitter.emit('show-agent');
}

onMounted(() => {
  const ua = navigator.userAgent;
  if (!ua.includes('iPhone') && !ua.includes('Android')) {
    let node = document.querySelector('#float-agent');
    node.style.setProperty('--kf-width', '5rem');
    node.style.setProperty('--kf-right', 'calc(50vw - var(--max-width)/2)');
  }
});
</script>

<style scoped>
#float-agent {
  --kf-width: 4rem;
  --kf-right: 0.5rem;
  position: fixed;
  right: var(--kf-right);
  top: calc(50% - 10rem);
  z-index: 10000;
}

#float-agent img {
  width: var(--kf-width);
}
</style>